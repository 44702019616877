import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import {
  getAuth,
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  updateEmail,
  updatePassword,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
import {
  getFirestore,
  getDoc,
  getDocs,
  collection,
  doc,
  query,
  where,
  orderBy,
  startAt,
  startAfter,
  limit,
  addDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
  runTransaction,
  deleteField,
  deleteDoc,
  writeBatch,
  onSnapshot,
  connectFirestoreEmulator
} from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getMetadata,
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  uploadString,
} from "firebase/storage";
import { combineLatest, of, from } from "rxjs";
import { map, switchMap } from "rxjs/operators";

var firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_CONFIG_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_CONFIG_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_CONFIG_APP_ID,
};
const app = initializeApp(firebaseConfig);
if (typeof window !== "undefined") {
  if(process.env.NODE_ENV==='development') window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.GATSBY_BUILD_KEY;
  initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(process.env.GATSBY_APPCHECK_KEY),
    isTokenAutoRefreshEnabled: true
  });
}
export const db = getFirestore(app);
const functions = getFunctions(app);
if(process.env.NODE_ENV==='development'){  
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  connectFirestoreEmulator(db, '127.0.0.1', 8080);
}
export const api = {
  eventReservationCreate: httpsCallable(functions, 'apiEventReservationCreated'),
  eventReservationAttendeesUpdate: httpsCallable(functions, 'apiEventReservationAttendeesUpdated'),
  eventReservationResultsUpdate: httpsCallable(functions, 'apiEventReservationResultsUpdated'),
  eventReservationReschedule: httpsCallable(functions, 'apiEventReservationRescheduled'),
  eventReservationApprove: httpsCallable(functions, 'apiEventReservationApproved'),
  eventReservationCancel: httpsCallable(functions, 'apiEventReservationCancelled'),
  eventReservationDeny: httpsCallable(functions, 'apiEventReservationDenied'),
  eventReservationMissingInfo: httpsCallable(functions, 'apiEventReservationMissingInfo'),
  eventReservationResults: httpsCallable(functions, 'apiEventReservationResults'),
  apiEventReservationPayment: httpsCallable(functions, 'apiEventReservationPayment'),
  eventReservationPayoutUpdate: httpsCallable(functions, 'apiEventReservationPayoutUpdated'),
  eventReservationLocationUpdate: httpsCallable(functions, 'apiEventReservationLocationUpdated'),
  eventReservationBulkApprove: httpsCallable(functions, 'apiEventReservationBulkApproved'),
  eventReservationBulkPayments: httpsCallable(functions, 'apiEventReservationBulkPayments'),
  brandCreativeUpdate: httpsCallable(functions, 'apiBrandCreativeUpdate'),
  brandCreativeRefresh: httpsCallable(functions, 'apiBrandCreativeRefresh'),
  donationTypeSave: httpsCallable(functions, 'apiDonationTypeSave'),
  donationApprove: httpsCallable(functions, 'apiDonationApproved'),
  donationCancel: httpsCallable(functions, 'apiDonationCancelled'),
  donationDeny: httpsCallable(functions, 'apiDonationDenied'),
  donationFulfill: httpsCallable(functions, 'apiDonationFulfilled'),
  donationMessageCreate: httpsCallable(functions, 'apiDonationMessageCreate'),
  aggregatesInitiate: httpsCallable(functions, 'apiAggregatesInitiate'),
  statsAll: httpsCallable(functions, 'apiStatsAll')
}
export const auth = getAuth(app);
export const emailVerification = () => sendEmailVerification(auth.currentUser);
export const storage = getStorage();
export const logout = () => signOut(auth);
export const authStateChanged = (f) => onAuthStateChanged(auth, f);
export const signInMethods = (e) => fetchSignInMethodsForEmail(auth, e);
export const createAccount = (e, p) =>
  createUserWithEmailAndPassword(auth, e, p);
export const signIn = (e, p) => signInWithEmailAndPassword(auth, e, p);
export const timestamp = serverTimestamp;
export const emailUpdate = updateEmail;
export const passwordUpdate = updatePassword;
export const docRef = (entity, id) => {
  if (id) return doc(db, entity, id);
  else return doc(collection(db, entity));
};
export const fileList = (ref) => listAll(ref);
export const storageRef = (filename) => ref(storage, filename);
export const uploadDataUrl = (ref, string) =>
  uploadString(ref, string, "data_url");
export const uploadTask = (ref, file) => uploadBytesResumable(ref, file);
export const fileMeta = (ref) => getMetadata(ref);
export const fileDelete = (ref) => deleteObject(ref);
export const fileURL = (ref) => getDownloadURL(ref);
export const transaction = (f) => runTransaction(db, f);
export const resetPassword = (email) => sendPasswordResetEmail(auth, email);
export const fsBatch = () => writeBatch(db);
export const snapshot = (_entity, _id, f, includeMetadataChanges) => {
  return onSnapshot(doc(db, _entity, _id), includeMetadataChanges, f);
};

export const updateDocument = async (model, payload) => {
  await updateDoc(doc(db, model.entity, model.id), { ...payload });
  return getDocument(model.entity, model.id);
};
export const updateDocumentRef = async (ref, payload) => {
  return updateDoc(ref, { ...payload });
};
export const deleteDocument = (entity, id) => deleteDoc(docRef(entity, id));

export const fieldDelete = deleteField;

export const createDocument = async (model, payload) => {
  if (model.id) {
    await setDoc(
      doc(db, model.entity, model.id),
      { ...payload },
      { merge: model.merge }
    );
    return getDocument(model.entity, model.id);
  } else {
    let obj = await addDoc(collection(db, model.entity), { ...payload });
    return { id: obj.id, ...payload };
  }
};

export const getSnapshot = async (_type, _id) => {
  return getDoc(doc(db, _type, _id));
};

export const getDocument = async (_type, _id, _joins) => {
  let $observables = from(getDoc(doc(db, _type, _id)))
    .pipe(
      switchMap((item) => {
        if (!item.exists()) return of(null);
        return of({ id: item.id, ...item.data() });
      })
    )
    .pipe(
      switchMap((item) => {
        if (!item) return of(null);
        if (!_joins) return of({ ...item });
        return combineLatest(
          ..._joins.map((x) => {
            if (x.key in item && item[x.key]) {
              return from(getDoc(doc(db, x.collection, item[x.key]))).pipe(
                switchMap((join) => {
                  if (join.exists()) {
                    return of({
                      ...item,
                      [x.field]: { id: join.id, ...join.data() },
                    });
                  } else {
                    return of({
                      ...item,
                      [x.key]: item.id,
                      [x.field]: { id: item.id },
                    });
                  }
                })
              );
            } else if (x.field in item) {
              let fieldID = newDocID(x.field + "s");
              let model = { id: fieldID, ...item[x.field] };
              delete item[x.field];
              return of({ ...item, [x.key]: fieldID, [x.field]: model });
            } else {
              return of({
                ...item,
                [x.key]: item.id,
                [x.field]: { id: item.id },
              });
            }
          })
        );
      })
    );

  return combineLatest($observables)
    .pipe(
      map((items) => {
        return [].concat.apply([], items);
      }),
      map((items) => {
        return items.reduce((acc, cur) =>
          Object.assign({}, { ...acc }, { ...cur })
        );
      })
    )
    .toPromise();
};

export const newDocID = (_type) => doc(collection(db, _type)).id;

export const getCollectionDocs = (_type, _options) => {
  
  let options = [];
  if (_options) {
    if (_options.where)
      _options.where.forEach((w) => options.push(where(...w)));
    if (_options.order) options.push(orderBy(..._options.order));
    if (_options.start) options.push(startAt(..._options.start));
    if (_options.after) options.push(startAfter(_options.after));
    if (_options.limit) options.push(limit(..._options.limit));
    
  }

  return getDocs(query(collection(db, _type), ...options));

}

export const getCollection = (_type, _options) => {
  let options = [];
  let joins = [];

  if (_options) {
    if (_options.where)
      _options.where.forEach((w) => options.push(where(...w)));
    if (_options.order) options.push(orderBy(..._options.order));
    if (_options.start) options.push(startAt(..._options.start));
    if (_options.after) options.push(startAfter(_options.after));
    if (_options.limit) options.push(limit(..._options.limit));
    if (_options.joins) joins = _options.joins;
  }

  let $observables = from(getDocs(query(collection(db, _type), ...options)))
    .pipe(
      switchMap((items) => {
        if (items.empty) return of([]);
        return combineLatest(
          ...items.docs.map((item) => {
            return of({ id: item.id, ...item.data() });
          })
        );
      })
    )
    .pipe(
      switchMap((items) => {
        if (items.length == 0) return of([]);
        return combineLatest(
          ...items.map((item) => {
            if (joins.length == 0) return of({ ...item });
            return combineLatest(
              ...joins.map((x) => {
                if (x.key in item && item[x.key]) {
                  return from(getDoc(doc(db, x.collection, item[x.key]))).pipe(
                    switchMap((join) => {
                      if (join.exists()) {
                        return of({
                          ...item,
                          [x.field]: {
                            id: join.id,
                            ...join.data(),
                          },
                        });
                      } else {
                        return of({
                          ...item,
                        });
                      }
                    })
                  );
                } else if (x.field in item) {
                  let fieldID = newDocID(x.field + "s");
                  let model = { id: fieldID, ...item[x.field] };
                  delete item[x.field];
                  return of({
                    ...item,
                    [x.key]: fieldID,
                    [x.field]: model,
                  });
                } else {
                  return of({
                    ...item,
                  });
                }
              })
            );
          })
        );
      })
    )
    .pipe(
      switchMap((items) => {
        if (items.length == 0) return of([]);
        return combineLatest(
          ...items.map((item) => {
            if (Array.isArray(item)) {
              return of(
                item.reduce((acc, cur) => {
                  let item = { ...acc.item };
                  delete acc.item;
                  return Object.assign({}, { ...acc }, { ...cur }, { ...item });
                })
              );
            } else {
              return of({ ...item });
            }
          })
        );
      })
    );

  return combineLatest($observables)
    .pipe(
      map((items) => {
        return [].concat.apply([], items);
      })
    )
    .pipe(
      map((items) => {
        return [].concat.apply([], items);
      })
    )
    .toPromise();
};

export const errorCodes = {
  "auth/wrong-password":
    "The password is invalid or the user does not have a password.",
  "auth/email-already-exists":
    "The provided email is already in use by an existing user. Each user must have a unique email.",
  "auth/id-token-expired": "The provided Firebase ID token is expired.",
  "auth/id-token-revoked": "The Firebase ID token has been revoked.",
  "auth/invalid-argument":
    "An invalid argument was provided to an Authentication method. The error message should contain additional information.",
  "auth/invalid-disabled-field":
    "The provided value for the disabled user property is invalid. It must be a boolean.",
  "auth/invalid-display-name":
    "The provided value for the displayName user property is invalid. It must be a non-empty string.",
  "auth/invalid-email-verified":
    "The provided value for the emailVerified user property is invalid. It must be a boolean.",
  "auth/invalid-password":
    "The provided value for the password user property is invalid. It must be a string with at least six characters.",
  "auth/invalid-photo-url":
    "The provided value for the photoURL user property is invalid. It must be a string URL.",
  "auth/credential-already-in-use":
    "This credential is already associated with a different user account.",
  "auth/custom-token-mismatch":
    "The custom token corresponds to a different audience.",
  "auth/requires-recent-login":
    "This operation is sensitive and requires recent authentication. Log in again before retrying this request.",
  "auth/email-change-needs-verification":
    "Multi-factor users must always have a verified email.",
  "auth/email-already-in-use":
    "The email address is already in use by another account.",
  "auth/cancelled-popup-request":
    "This operation has been cancelled due to another conflicting popup being opened.",
  "auth/internal-error": "There was an error authenticating your account.",
  "auth/invalid-user-token":
    "This user's credential isn't valid for this project. This can happen if the user's token has been tampered with, or if the user isn't for the project associated with this API key.",
  "auth/invalid-auth-event": "An internal AuthError has occurred.",
  "auth/invalid-email": "Please enter a valid email address.",
  "auth/invalid-credential":
    "The supplied auth credential is malformed or has expired.",
  "auth/missing-email": "Please enter a valid email address.",
  "auth/account-exists-with-different-credential":
    "An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.",
  "auth/network-request-failed":
    "A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred.",
  "auth/no-auth-event": "An internal AuthError has occurred.",
  "auth/no-such-provider":
    "User was not linked to an account with the given provider.",
  "auth/null-user":
    "A null user object was provided as the argument for an operation which requires a non-null user object.",
  "auth/popup-blocked":
    "Unable to establish a connection with the popup. It may have been blocked by the browser.",
  "auth/popup-closed-by-user":
    "The popup has been closed by the user before finalizing the operation.",
  "auth/provider-already-linked":
    "User can only be linked to one identity for the given provider.",
  "auth/redirect-cancelled-by-user":
    "The redirect operation has been cancelled by the user before finalizing.",
  "auth/redirect-operation-pending":
    "A redirect sign-in operation is already pending.",
  "auth/rejected-credential":
    "The request contains malformed or mismatching credentials.",
  "auth/timeout": "The operation has timed out.",
  "auth/user-token-expired":
    "The user's credential is no longer valid. The user must sign in again.",
  "auth/too-many-requests":
    "We have blocked all requests from this device due to unusual activity. Try again later.",
  "auth/unverified-email": "The operation requires a verified email.",
  "auth/user-cancelled":
    "The user did not grant your application the permissions it requested.",
  "auth/user-not-found":
    "There is no user record corresponding to this identifier. The user may have been deleted.",
  "auth/user-disabled":
    "The user account has been disabled by an administrator.",
  "auth/user-mismatch":
    "The supplied credentials do not correspond to the previously signed in user.",
  "auth/weak-password": "The password must be 6 characters long or more.",
  "auth/web-storage-unsupported":
    "This browser is not supported or 3rd party cookies and data may be disabled.",
};
