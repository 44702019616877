import { combineReducers } from "redux";
import accountSlice from "./slices/account";
import dashboardSlice from "./slices/dashboard";
import eventReservationsSlice from "./slices/event_reservations";
import donationsSlice from "./slices/donations";
import algoliaSlice from "./slices/algolia";
import reservationSlice from "./slices/reservation";
import statsSlice from "./slices/stats";
import profileSlice from "./slices/profile";
import creativeSlice from "./slices/creative";
import settingAccountSlice from "./slices/settingAccount";
import eventTypesSlice from "./slices/eventTypes";
import donationTypesSlice from "./slices/donationTypes";
import brandSlice from "./slices/brand";
import pagerSlice from "./slices/pager";
import formBuilderSlice from "./slices/formBuilder";
import donationSlice from "./slices/donation";

export default combineReducers({
  account: accountSlice,
  dashboard: dashboardSlice,
  event_reservations: eventReservationsSlice,
  donations: donationsSlice,
  algolia: algoliaSlice,
  reservation: reservationSlice,
  stats: statsSlice,
  profile: profileSlice,
  creative: creativeSlice,
  settingsAccount: settingAccountSlice,
  eventTypes: eventTypesSlice,
  donationTypes: donationTypesSlice,
  brand: brandSlice,
  pager: pagerSlice,
  formBuilder: formBuilderSlice,
  donation: donationSlice
});
